<template>
  <v-container class="mt-5 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="transparent elevation-0">
          <v-card-title primary-title class="text-subtitle-1">
          	<h1 class="text-typo font-weight-bolder mb-0">Historial de ventas por Ciclo </h1>
            <v-spacer></v-spacer>
            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( modalidades  , 'MODALIDADES_ERP')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="getIniciarConsulta( )"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
          </v-card-title>

          <v-card-text class="black--text mt-6">
            <v-row>

              <v-col cols="12" sm="4" lg="2" xl="1">
              	<label class="grey--text">Selecciona un año</label>
	              <v-autocomplete
                  filled
                  clearable
                  v-model="anio"
                  :items="anios"
                  label="2019"
                  dense
                  single-line
                  hide-details
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="4" lg="3" xl="2">
              	<label class="grey--text">Ciclo inicial</label>
	              <v-autocomplete
                  filled
                  clearable
                  v-model="ciclo"
                  :items="filterCiclos"
                  label="01_20"
                  dense
                  item-text="ciclo"
                  item-value="id_ciclo"
                  return-object
                  single-line
                  hide-details
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="4" lg="3" xl="2">
              	<label class="grey--text">Ciclo final</label>
	              <v-autocomplete
                  filled
                  clearable
                  v-model="cicloFinal"
                  :items="ciclosFinales"
                  label="12_20"
                  dense
                  item-text="ciclo"
                  item-value="id_ciclo"
                  return-object
                  single-line
                  hide-details
                >
                </v-autocomplete>
              </v-col>
	  		      <v-spacer></v-spacer>
            </v-row>

            <v-row class="mt-0 grey--text">
            	<v-col cols="12" md="3" lg="2" class="mt-0 pb-0">
                <label>Activos</label>
                <v-select
                  :items="['Activo', 'Todo']"
                  v-model="activos"
                  label="Activos"
                  filled
                  dense
                  single-line
                ></v-select>
            	</v-col>

              <v-col cols="12" md="3" lg="2" class="mt-0 pb-0">
                <label>Ventas</label>
                <v-select
                  :items="['Alumnos', 'Dinero']"
                  v-model="ventas"
                  label="Venta"
                  filled
                  dense
                  single-line
                ></v-select>
              </v-col>

              <v-col cols="12" md="3" lg="2" class="mt-0 pb-0">
                <label>Enfoque</label>
                <v-select
                  :items="['Vendedora', 'Plantel']"
                  v-model="enfoque"
                  label="Enfoque"
                  filled
                  dense
                  single-line
                ></v-select>
              </v-col>

              <v-col cols="12" md="3" lg="2" class="mt-0 pb-0" v-if="enfoque == 'Plantel'">
                <label class="grey--text">TIPO de consulta</label>
                <v-select
                  :items="['NI', 'RI', 'TODO']"
                  v-model="tipoConsulta"
                  label="RI O NI"
                  filled
                  dense
                  single-line
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" lg="4" class="mt-0 pt-0" v-if="enfoque == 'Vendedora' && vendedorasAll.length">
                <label class="grey--text">Vendedora</label>
                <v-autocomplete
                  v-if="enfoque == 'Vendedora'"
                  filled
                  clearable
                  v-model="vendedora"
                  :items="vendedorasAll"
                  label="Selecciona una vendedora"
                  dense
                  item-text="vendedora"
                  item-value="id_usuario"
                  single-line
                  hide-details
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" md="6" lg="4" class="mt-0 pt-0" v-if="enfoque == 'Plantel' && vendedorasAll.length">
                <label class="grey--text">Plantel</label>
                <v-autocomplete
                  v-if="enfoque == 'Plantel'"
                  filled
                  clearable
                  v-model="plantel"
                  :items="vendedorasAll"
                  label="Selecciona un plantel"
                  dense
                  item-text="plantel"
                  item-value="plantel"
                  single-line
                  hide-details
                >
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row v-if="headers.length">
            	<v-col cols="12" :lg="plantel ? 10 : 12">
            		<v-card class="shadowCard">
            			<v-card-text v-if="verGraficaInbi">
                    <vue-apex-charts 
                      v-if="verGraficaInbi" 
                      :options="chartOptions" 
                      :series="seriesInbi" 
                      type="bar"
                    ></vue-apex-charts>
            			</v-card-text>
            		  <v-card-text>
            		    <!-- Tabla de ingresos y egresos de fast -->
					          <v-data-table
					            :headers="headers"
					            :items="filterVendedorasInbiAll"
					            class="elevation-0"
					            :search="search"
					            dense
					            mobile-breakpoint="200"
					          >
					          </v-data-table>
            		  </v-card-text>
            		</v-card>
            	</v-col>
            </v-row>


            <v-row v-if="headers.length">
            	<v-col cols="12" :lg="plantel ? 10 : 12">
            		<v-card class="shadowCard">
            			<v-card-text v-if="verGraficaFast">
                    <vue-apex-charts 
                      v-if="verGraficaFast" 
                      :options="chartOptions" 
                      :series="seriesFast" 
                      type="bar"
                    ></vue-apex-charts>
            			</v-card-text>
            		  <v-card-text>
            		    <!-- Tabla de ingresos y egresos de fast -->
					          <v-data-table
					            :headers="headers"
					            :items="filterVendedorasFastAll"
					            class="elevation-0"
					            :search="search"
					            dense
					            mobile-breakpoint="200"
					          >
					          </v-data-table>
            		  </v-card-text>
            		</v-card>
            	</v-col>
            </v-row>


            <v-row v-if="headers.length && plantel ">
              <v-col cols="12" :lg="plantel ? 10 : 12">
                <v-card class="shadowCard">
                  <v-card-title primary-title>
                    <v-spacer></v-spacer>
                    <span v-if="plantel" class="text-h6 ma-4 mt-4">{{ vendedorasAll.find( el => el.plantel == plantel).plantel }}</span>
                    <v-spacer></v-spacer>
                  </v-card-title>
                  <v-card-text v-if="verGraficaGeneral">
                    <vue-apex-charts 
                      v-if="verGraficaGeneral && plantel" 
                      :options="chartOptions" 
                      :series="seriesGeneral" 
                      type="area"
                    ></vue-apex-charts>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';

  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  import VueApexCharts from 'vue-apexcharts'

  const formatter = new Intl.NumberFormat('en-US', {
	  style: 'currency',
	  currency: 'USD',
	});

  export default {

    components:{
      Alerta,
      carga,
      VueApexCharts,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      anios:[],
      ciclos:[],
      ciclo: null,
      anio: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 4),
      cicloFinal: null,
      ciclosFinales:[],

      headers:[],
      vendedorasInbiAll:[],
      vendedorasFastAll:[],
      search:'',

      verGrafica: false,
      verGraficaFast: false,
      seriesFast: [],

      verGraficaInbi: false,
      seriesInbi: [],

      seriesGeneral:[],
      activos:'Activo',
      enfoque:'Vendedora',
      ventas: 'Alumnos',

      chart: null,

      verGraficaGeneral: false,

      chartOptions: {
        chart: {
          type: 'bar',
          height: 500,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          },
          annotations:{
            y: 25, // Valor en el eje y donde deseas ubicar la línea tipo "meta"
            borderColor: '#FF4560', // Color de la línea
            label: {
              borderColor: '#FF4560', // Color de la etiqueta
              style: {
                color: '#fff', // Color del texto de la etiqueta
                background: '#FF4560', // Fondo de la etiqueta
              },
              text: 'Meta', // Texto de la etiqueta
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 15,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              },
              formatter: function (val) {
                return parseFloat(val).toLocaleString('en-US', { style: 'currency', currency: 'USD' }); // Formato de moneda
              },
            }
          },
        },
        xaxis: {
          categories: [],
        },
        grid: {
          row: {
            colors: ['#fff', '#f2f2f2']
          }
        },
        yaxis: {
          title: {
            text: 'Plantel / Vendedora',
          },
        },
        goals: [
          {
            name: 'Expected',
            value: 52,
            strokeColor: '#775DD0'
          }
        ],
        fill: {
          opacity: 1
        }
      },

      chartSeries: [],
      vendedora: null,
      vendedorasAll : [],
      plantel: null,
      tipoConsulta: 'NI'
    }),

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),

      filterCiclos( ){
      	if( this.anio ){
      		return this.ciclos.filter( el => { return el.anio == this.anio })
      	}else{
      		return []
      	}
      },

      filterVendedorasInbiAll ( ) {
        let data = this.vendedorasInbiAll
      	
        if( this.activos == 'Activo' ){
      		// rediseñar tabla
      		data = data.filter( el => { return el.activo_sn == 1 }) 
      	}
      	
        if( this.vendedora ){
          // rediseñar tabla
          data = data.filter( el => { return el.activo_sn == 1 && el.id_usuario == this.vendedora}) 
        }

        if( this.plantel ){
          // rediseñar tabla
          data = data.filter( el => { return el.activo_sn == 1 && el.plantel == this.plantel}) 
        }

        return data
      },

			filterVendedorasFastAll ( ) {
        let data = this.vendedorasFastAll
        
        if( this.activos == 'Activo' ){
          // rediseñar tabla
          data = data.filter( el => { return el.activo_sn == 1 }) 
        }
        
        if( this.vendedora ){
          // rediseñar tabla
          data = data.filter( el => { return el.activo_sn == 1 && el.id_usuario == this.vendedora}) 
        }

        if( this.plantel ){
          // rediseñar tabla
          data = data.filter( el => { return el.activo_sn == 1 && el.plantel == this.plantel}) 
        }

        return data
			},

    },

    async created () {
      await this.initialize()
    },

    watch:{
    	activos( value ){
        if( this.enfoque == 'Vendedora'){
          this.updateTabla()
        }else{
          this.updateTablaPlantel()
        }
      },

      vendedora ( value ){
        if( this.enfoque == 'Vendedora')
          this.updateTabla()
        else
          this.updateTablaPlantel()
      },

      plantel ( value ){
        if( this.enfoque == 'Vendedora')
          this.updateTabla()
        else
          this.updateTablaPlantel()
      }
    },

    methods: {
      initialize () {
        this.cargar    = true
        this.ciclos    = []

        return this.$http.get('reporte.anios.ciclos' ).then(response=>{
        	this.anios   = response.data.anios
        	this.ciclos  = response.data.ciclosAll
          this.anio    = parseInt( (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 4) )

          const ciclo01Actual = this.ciclos.find( el => el.anio == this.anio && el.ciclo.match('01') )

          this.ciclo = ciclo01Actual ? ciclo01Actual : null

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getIniciarConsulta ( ){
        this.chartOptions.xaxis.categories     = []
        this.seriesInbi                        = []
        this.seriesFast                        = []
        this.chartSeries                       = []
        this.verGrafica                        = false
        this.verGraficaFast                    = false
        this.verGraficaInbi                    = false

        if(this.enfoque == 'Vendedora'){
          this.getHistorialVentas()
        }else{
          this.getHistorialVentasPlantel()
        }
      },

      getHistorialVentas(){
      	this.cargar        = true
        this.ciclosFinales = []

        let payload = this.ciclo

        payload['ventas'] = this.ventas

        this.$http.post('reporte.historial.ventas', payload ).then(response=>{

        	this.ciclosFinales     = response.data.ciclosFinales
        	this.cicloFinal        = response.data.cicloFinal
        	this.headers           = response.data.headers
        	this.vendedorasInbiAll = response.data.vendedorasInbiAll
          this.vendedorasFastAll = response.data.vendedorasFastAll
        	this.vendedorasAll     = response.data.vendedorasAll.filter( el => { return el.activo_sn == 1 })

          this.updateTabla()

          this.cargar         = false

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getHistorialVentasPlantel(){
        this.cargar        = true
        this.ciclosFinales = []

        let payload = this.ciclo

        payload['ventas'] = this.ventas

        let path = ''

        if( this.tipoConsulta == 'NI' )  { path = 'reporte.historial.ventas.plantel'      }
        if( this.tipoConsulta == 'RI' )  { path = 'reporte.historial.ventas.plantel.ri'   }
        if( this.tipoConsulta == 'TODO' ){ path = 'reporte.historial.ventas.plantel.todo' }

        this.$http.post( path , payload ).then(response=>{

          this.ciclosFinales     = response.data.ciclosFinales
          this.cicloFinal        = response.data.cicloFinal
          this.headers           = response.data.headers
          this.vendedorasInbiAll = response.data.vendedorasInbiAll
          this.vendedorasFastAll = response.data.vendedorasFastAll
          this.vendedorasAll     = response.data.vendedorasAll.filter( el => { return el.activo_sn == 1 })

          console.log( this.vendedorasInbiAll )

          this.updateTablaPlantel()

          this.verGrafica     = true
          this.verGraficaFast = true
          this.verGraficaInbi = true
          this.cargar         = false

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      updateTabla( ){
        this.cargar         = true
        this.verGrafica     = false
      	this.verGraficaFast = false
        this.verGraficaInbi = false

        this.chartOptions.xaxis.categories     = []
        this.seriesInbi                        = []
        this.seriesFast                        = []

        for( const i in this.filterVendedorasInbiAll ){

      		const { vendedora } = this.filterVendedorasInbiAll[i]

          let data = []
      		for( const j in this.ciclosFinales ){

            let totales = 0
            
            if( this.ventas == 'Alumnos')
      			  totales = this.ciclosFinales[j].datos_inbi.filter( el => { return el.vendedora == vendedora && el.vendedora != 'TOTAL'}).length
      			else
              totales = this.ciclosFinales[j].datos_inbi.filter( el => { return el.vendedora == vendedora && el.vendedora != 'TOTAL'}).map(item => item.pagado).reduce((prev, curr) => prev + curr, 0).toFixed(2)

            data.push(totales)
      		}

          if( vendedora != 'TOTAL' ){
        		this.seriesInbi.push({
        			name: this.filterVendedorasInbiAll[i].vendedora,
        			data
      			})
            // })
          }
      	}

      	for( const i in this.filterVendedorasFastAll ){

      		const { vendedora } = this.filterVendedorasFastAll[i]

      		let data = []
          let data2 = []

      		
          for( const j in this.ciclosFinales ){
      			
            let totales = 0
            
            if( this.ventas == 'Alumnos')
              totales = this.ciclosFinales[j].datos_fast.filter( el => { return el.vendedora == vendedora && el.vendedora != 'TOTAL'}).length
            else
              totales = this.ciclosFinales[j].datos_fast.filter( el => { return el.vendedora == vendedora && el.vendedora != 'TOTAL'}).map(item => item.pagado).reduce((prev, curr) => prev + curr, 0).toFixed(2)
            
            data.push(totales)
      		}

          if( vendedora != 'TOTAL' ){
        		this.seriesFast.push({
        			name: this.filterVendedorasFastAll[i].vendedora,
        			data
      			})

          }

      	}

        for(const i in this.ciclosFinales){
          this.chartOptions.xaxis.categories.push( this.ciclosFinales[i].ciclo )
        }

        this.verGrafica     = true
        this.verGraficaFast = true
        this.verGraficaInbi = true
        this.cargar         = false
      },

      updateTablaPlantel( ){
        this.verGrafica        = false
        this.verGraficaFast    = false
        this.verGraficaInbi    = false
        this.verGraficaGeneral = false

        this.chartOptions.xaxis.categories     = []
        this.seriesInbi      = []
        this.seriesFast      = []
        this.chartSeries     = []
        this.seriesGeneral   = []
        

        for( const i in this.filterVendedorasInbiAll ){

          this.filterVendedorasInbiAll[i]['activo_sn'] = 1

          const { plantel } = this.filterVendedorasInbiAll[i]

          let data = []
          for( const j in this.ciclosFinales ){

            let totales = 0

            if( this.ventas == 'Alumnos')
              totales = this.ciclosFinales[j].datos_inbi.filter( el => { return el.plantel == plantel && el.plantel != 'TOTAL'}).length
            else
              totales = this.ciclosFinales[j].datos_inbi.filter( el => { return el.plantel == plantel && el.plantel != 'TOTAL'}).map(item => item.pagado).reduce((prev, curr) => prev + curr, 0).toFixed(2)

            data.push(totales)
          }

          if( plantel != 'TOTAL' ){
            this.seriesInbi.push({
              name: this.filterVendedorasInbiAll[i].plantel,
              data
            })
          }
        }

        for( const i in this.filterVendedorasFastAll ){
          this.filterVendedorasFastAll[i]['activo_sn'] = 1

          const { plantel } = this.filterVendedorasFastAll[i]

          let data = []
          let data2 = []
          for( const j in this.ciclosFinales ){
            
            let totales = 0

            if( this.ventas == 'Alumnos')
              totales = this.ciclosFinales[j].datos_fast.filter( el => { return el.plantel == plantel && el.plantel != 'TOTAL' }).length
            else
              totales = this.ciclosFinales[j].datos_fast.filter( el => { return el.plantel == plantel && el.plantel != 'TOTAL' }).map(item => item.pagado).reduce((prev, curr) => prev + curr, 0).toFixed(2)

            // let riPlantel = 
            data.push(totales)
          }

          if( plantel != 'TOTAL' ){
            this.seriesFast.push({
              name: this.filterVendedorasFastAll[i].plantel,
              data
            })
          }
        }

        // GRAFICA DE RI

        if( this.plantel ){

          let datos = []

          if( this.plantel.match('FAST') ){
            datos = this.filterVendedorasFastAll.find( el => el.plantel == this.plantel )
          }else{
            datos = this.filterVendedorasInbiAll.find( el => el.plantel == this.plantel )
          }


          const propiedades = Object.getOwnPropertyNames(datos);

          let arregloDatos = propiedades.filter( el => { return el.match('_ri_') })
          console.log(arregloDatos); // Esto mostrará ["nombre", "edad", "ciudad"]

          let data = []

          for( const j in arregloDatos ){
            data.push( datos[arregloDatos[j]].toFixed(2) )
          }

          console.log( data )
          if( this.plantel != 'TOTAL' ){
            this.seriesGeneral.push({
              name: this.plantel,
              data
            })
          }

          console.log( this.seriesGeneral )


        }


        for(const i in this.ciclosFinales){
          this.chartOptions.xaxis.categories.push( this.ciclosFinales[i].ciclo )
        }

        this.verGrafica     = true
        this.verGraficaFast = true
        this.verGraficaInbi = true
        this.verGraficaGeneral = true
        this.cargar         = false
      },

      exportar(){
        this.exportarData()
      },
    },
  }
</script>
<style scoped>

  .avatares{
    background-image: linear-gradient(310deg,#17ad37,#98ec2d) !important;
  }


  .anteriores{
    background: rgb(135,45,176);
    background: linear-gradient(149deg, rgba(135,45,176,0.9612219887955182) 0%, rgba(235,44,244,0.9836309523809523) 100%);
  }

  .gradient-text {
    background-image: linear-gradient(149deg, rgba(135,45,176,0.9612219887955182) 0%, rgba(235,44,244,0.9836309523809523) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .gradient-text-percent{
    background-image: linear-gradient(149deg, rgb(8,110,0) 0%, rgba(44,244,57,0.9836309523809523) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .text-typo{
  	color: #000D4A !important;
  }

  .apexcharts-canvas {
    max-width: 600px;
    margin: 0 auto;
  }

</style>
